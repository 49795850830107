import React from "react";
import Style from "./Portfolio.module.scss";

import spotify from "../img/spotify.jpg";
import { info, colors } from "../Info/Info";
import {Link} from "react-router-dom";

import rubirosa from "../img/rubirosa.jpg";



export default function Portfolio() {
  let portfolioInfo = info.portfolio;

  let mocks = ["https://i.guim.co.uk/img/media/987ecaa6b927de70156be6b49ec4b2d01d22a7ce/0_280_4197_2518/master/4197.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=b8cad3934d802e63fb73b66c60bbce2b",
  "https://www.hollywoodreporter.com/wp-content/uploads/2017/04/chrome_hearts_nyc_-_embed_1_-_h_2017.jpg",
  "https://wwd.com/wp-content/uploads/2023/02/Proenza-Schouler-Portraits-LM-01-copy.jpg",
  "https://pagesix.com/wp-content/uploads/sites/3/2023/09/NYPICHPDPICT000034529416.jpg",
  "https://assets.vogue.com/photos/627525a2c1cbf41735f7ba7a/4:3/w_2400,h_1800,c_limit/VO0622_Cover_Logo.jpg",
  "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton--Stfi_Montenapoleone_Store_Newpicture_DI3.jpg",
  "https://cdn.vox-cdn.com/uploads/chorus_image/image/69804564/1234216103.0.jpg", 
  "https://assets3.thrillist.com/v1/image/3055793/1000x666/flatten;crop;webp=auto;jpeg_quality=60.jpg", 
  "https://d3emaq2p21aram.cloudfront.net/media/cache/venue_carousel/uploads/venues/37446/CecconisDumbo-TheVenueReport-04.jpg", 
  "https://hips.hearstapps.com/harpersbazaar/assets/16/40/1475516467-hbz-eat-chic-le-coucou-00-index.jpg",
  "https://architizer-prod.imgix.net/mediadata/projects/442009/d8ae780c.jpg?w=1680&q=60&auto=format,compress&cs=strip",
  "https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2022%2F08%2Fsalt-bae-nusr-et-steakhouse-new-york-meatpacking-restaurant-inside-look-info-000.jpg?w=960&cbr=1&q=90&fit=max",
  "https://www.insidehook.com/wp-content/uploads/2022/11/nubeluz-bar-jose-andres.jpg?fit=1500%2C1000",
  "https://resizer.otstatic.com/v2/photos/wide-huge/2/47349064.jpg",
  "https://media.guestofaguest.com/t_card_large/gofg-media/2023/02/1/56706/caviar_kaspia_at_the_mark_interior231_brett_wood_lowres_(2).jpeg",
  "https://media-cdn.tripadvisor.com/media/photo-s/1d/3a/99/a3/main-dining-room.jpg",
  "https://cdn.vox-cdn.com/thumbor/adjU8J-5PwfRHb2RdZNTpDMCJ9A=/0x0:5000x3333/1200x675/filters:focal(2079x1145:2879x1945)/cdn.vox-cdn.com/uploads/chorus_image/image/61550469/20180920_GH_MANHATTA_1007.0.jpg",
  "https://media.timeout.com/images/105872332/image.jpg",
  "https://cdn4.equinox-hotels.com/wp-content/uploads/2022/06/20025834/equinox_Hero_Final.webp",
  "https://www.rockwellgroup.com/assets/media/Peak-Restaurant_116_1585858601.jpg",
  "https://nusretusa.com/wp-content/uploads/2022/12/nusret-newyork-midtown.jpeg",
  "https://assets3.thrillist.com/v1/image/3004300/1000x666/flatten;crop;webp=auto;jpeg_quality=60.jpg",
  "https://thepointsguy.global.ssl.fastly.net/us/originals/2023/03/Centurion-New-York-American-Express-Zach-Griff-76.jpg",
  "https://nypost.com/wp-content/uploads/sites/2/2023/05/NYPICHPDPICT000011738189.jpg?quality=75&strip=all&w=744",
  "https://damselindior.com/wp-content/uploads/2016/01/IMG_5836-1280x853.jpg",
  "https://www.vivianbabutsphotography.com/img/s/v-3/p1554141307-5.jpg",
  "https://i0.wp.com/media.theskinnypignyc.com/wp-content/uploads/2019/10/14215339/IMG_1664.jpg?fit=1920%2C1280&ssl=1",
  "https://dz0zjhi21dz2t.cloudfront.net/media/81904/photos/86428/2048.jpg",
  "https://pagesix.com/wp-content/uploads/sites/3/2020/07/cipriani-downtown.jpg?quality=75&strip=all",
  "https://www.bunboyeatsnyc.com/wp-content/uploads/2015/12/IMG_0364.jpg",
  "https://media.guestofaguest.com/t_article_content/gofg-media/2018/01/1/50290/the_palm_court_interior_bar_area_cred_oleg_march_best_(2).jpg",
  "https://mrhipster.com/wp/wp-content/uploads/2001/08/jg-melon-ues.jpg",
  "https://markhotel-production.s3.amazonaws.com/app/uploads/2023/02/BFA_-Final-Edit-of-639-2000x1343-1.jpg",
  "https://media.timeout.com/images/103940714/750/422/image.jpg",
  "https://nypost.com/wp-content/uploads/sites/2/2020/04/200425-nello-owner1.jpg?quality=75&strip=all",
  rubirosa,];


  function createGrid() {
    let results = [];
    let j = 0;

    for (let i = 0; i < portfolioInfo.length; i++) {  
      let partOne = info.portfolio[i].sourceLink;
      let partTwo = info.portfolio[i].title;
      let linkName = partOne + '?title=' + partTwo;

      results.push(
        <div key={i} className={Style.block}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={info.portfolio[i].liveLink}
          >
            <img
              alt="Project screenshot"
              className={Style.image}
              src={mocks[j++]}
            />
          </a>
          <h1>{info.portfolio[i].title}</h1>
          {i !== 18 && (
            
            <Link 
            to={linkName}
            style={{ backgroundColor: colors[1] }}
            className={Style.source}
            >
              Read
            </Link>


          )}

        </div>
      );
    }
    return results;
  }

  return (
    <main>
      <div className={Style.projectsGrid}>{createGrid()}</div>
    </main>
  );
}
