import React from "react";
import Style from "./About.module.scss";
import classNames from "classnames";
import { info, colors } from "../Info/Info";

export default function About() {
  const iconClass = "fa fa-circle";

  let hobbies = info.hobbies;
  let languages = info.languages;
  let frameworks = info.frameworks;
  let tools = info.tools;

  function createHobbiesList() {
    let results = [];
    for (let i = 0; i < hobbies.length; i++) {
      let value = hobbies[i];
      results.push(
        <li key={i}>
          <span aria-label="emoji" role="img" className={Style.emoji}>
            {value[0]}
          </span>{" "}
          {value[1]}
        </li>
      );
    }
    return results;
  }

  function languagesList() {
    let results = [];
    for (let i = 0; i < languages.length; i++) {
      let value = languages[i];
      results.push(
        <li key={i}>
          {value[0]} {value[1]}
        </li>
      );
    }
    return results;
  }



  return (
    <div className={Style.about}>
      <div className={classNames(Style.terminal, Style.shadowed)}>
        <div className={Style.topRow}>
          <i className={classNames(iconClass, Style.red)} />
          <i className={classNames(iconClass, Style.amber)} />
          <i className={classNames(iconClass, Style.green)} />
        </div>
        <div className={Style.window}>
        <iframe style={{borderRadius: 0}} src="https://open.spotify.com/embed/playlist/5ebCAeuTxWePXNqiy0u4ni?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />
        </div>
      </div>


    </div>
  );
}
