import React from "react";
import Style from "./Blog.module.scss";
import classNames from "classnames";
import {
    BrowserRouter as Router,
    useLocation,
    Link
  } from "react-router-dom"

import { info, colors } from "../Info/Info";

export default function Blog() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)

    let title = queryParameters.get("title");
    let portfolioInfo = info.portfolio;


    function createBlog() {
        
        let linkName = "";
        let results = [];
        let j = 0;

        for (let i = 0; i < portfolioInfo.length; i++) { 

            if(info.portfolio[i].title == title) {
                results.push(
                    <div>
                        <h1>
                        {title}
                        </h1>
                    
                        <div className={Style.block}>
                            <img
                            className={Style.image}
                            src={info.portfolio[i].image}
                            />
                        </div>

                        <p className={Style.paragraph}>
                            {info.portfolio[i].theStory}
                        </p>
                        <div className={Style.block}>
                            <Link 
                            to={linkName}
                            style={{ backgroundColor: colors[1] }}
                            className={Style.source}
                            >
                            Learn More
                            </Link>
                        </div>
                        
                    </div>
                    
                )
                return results;
            }
        }
    }
   
  return (
    <main>
        <div className={Style.projectsGrid}>{createBlog()}</div>
    </main>
  );
}
