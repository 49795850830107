import React from "react";
import pp from "../img/nyc.png";
import spotify from "../img/spotify.jpg";
import emp from "../img/emp.png";
import ch from "../img/ch.png";
import gucci from "../img/gucci.png";


export let colors = ["rgb(1, 208, 108)", "rgb(5, 186, 199)"];

/**
 * Generates and returns a font awesome
 * icon for the name provided
 * @param {string} name
 * @param {string} [mainClass] the main icon class (fab, fas, fa...)
 */
export const fetchIcon = (name, mainClass = 'fab') => {
  return <i className={`${mainClass} fa-${name}`} aria-hidden="true" />
};

export const info = {
  firstName: "Prospect",
  lastName: "NYC",
  initials: "PNYC",
  positions: ["marketing", "public relations", "branding", "spin"],
  selfPortrait: pp,
  gradient: `linear-gradient(135deg, ${colors})`,
  miniBio: [
    ["🗽", "new york, new york"],
    ["🔥", "pr@prospectnewyorkcity.com"],
  ],
  socials: [
    ["https://www.linkedin.com/company/prospectnewyorkcity", fetchIcon('linkedin', 'fa')],
    ["https://instagram.com/prospectnewyorkcity", fetchIcon('instagram')],
    ["https://www.tiktok.com/@prospectnyc", fetchIcon('tiktok')],
    ["https://whatsapp.com/channel/0029Va4HINp2phHQwWbc9238", fetchIcon('whatsapp')],
    ["https://www.youtube.com/@prospectnewyorkcity", fetchIcon('youtube')],
    ["https://www.facebook.com/prospectnewyorkcity", fetchIcon('facebook')],
    ["https://www.snapchat.com/add/prospectnyc", fetchIcon('snapchat')],
  ],
  bio:
    "Prospect is one of the premiere talent management firms in New York City working with powerhouse New Yorkers and influencer talent. Prospect has become one of the most competitive full service management/branding firms taking a 360 approach across music, endorsements, social media strategy, live touring, personal appearances and consumer products.",
  hobbies: [
    ["🛖", "public relations"],
    ["📖", "branding"],
    ["🏎️", "growth marketing"],
    ["✍", "spin"],
    ["🧑‍💻", "networking"],
  ],
  portfolio: [
    {
      title: "Gucci",
      theStory: "Gucci is an Italian luxury fashion brand that was founded in 1921 by Guccio Gucci in Florence, Tuscany. Gucci is one of the oldest Italian fashion brands still in operation today. The brand started out as a luggage manufacturer, producing luxury travel goods for Italy's wealthy upper-classes, as well as equestrian equipment. ",
      liveLink: "#",
      image: gucci,
      sourceLink: "/blog",
    },
    {
      title: "Chrome Hearts",
      theStory: "Chrome Hearts is an authentic American luxury brand founded in 1988 by motorcycle enthusiast Richard Stark. Born from the love of motorcycles, Chrome Hearts got its start from Richard's self made leather chaps, pants, jackets and vests. Chrome Hearts specializes in high end .",
      liveLink: "#",
      image: ch,
      sourceLink: "/blog",
    },
    {
      title: "Proenza Schouler",
      theStory: "Proenza Schouler is a New York-based fashion label that creates luxury clothing and accessories for women. The brand was founded in 2002 by designers Jack McCollough and Lazaro Hernandez. Proenza Schouler's work is known for its use of fine materials and a sense of effortlessness and ease. ",
      liveLink: "#",
      image: "https://cdn.vox-cdn.com/thumbor/KJysBHzJdHRzZ3NKFoSrKg0yEak=/147x0:2534x1790/1400x788/filters:focal(147x0:2534x1790):format(jpeg)/cdn.vox-cdn.com/uploads/chorus_image/image/49787503/proenza-schouler-getty-ps1-bag-london-fashion-week-2015.0.0.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Alice + Olivia",
      theStory: "Alice + Olivia is a luxury womenswear brand that was launched in 2002 by Stacey Bendet. The brand is known for its vibrant, colorful collections of dresses, separates, footwear, accessories, and eyewear. Retail prices range from $190 for a polo top to around $1,895 for a silk evening gown. ",
      liveLink: "#",
      image: "https://hpvillage.com/wp-content/uploads/2022/08/8.17.22-Alice-Olivia-Store-Front.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Vogue",
      theStory: "Vogue is a monthly fashion and lifestyle magazine that covers topics such as beauty, culture, and runway. ",
      liveLink: "#",
      image: "https://media.vogue.fr/photos/637506be266563355fc2911c/4:3/w_2478,h_1859,c_limit/Cover-1033-sanscodebarre.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Louis Vuitton",
      theStory: "Louis Vuitton Malletier, commonly known as Louis Vuitton, is a French luxury fashion house and company founded in 1854 by Louis Vuitton.",
      liveLink: "#",
      image: "https://eu.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton--Stfi_Louis_Vuitton_San_Francisco_Bloomingdales_842_1_DI3.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Eleven Madison Park",
      theStory: "Eleven Madison Park is a prestigious and highly acclaimed restaurant located in New York City. As of my last knowledge update in September 2021, it was considered one of the finest dining establishments in the world. Here are some key details about Eleven Madison Park:\n\n\n Location: The restaurant is situated at 11 Madison Avenue in the Flatiron District of Manhattan, New York City. The name Eleven Madison Park is derived from its address. \n\n\nCuisine: Eleven Madison Park is known for its contemporary American cuisine with a focus on locally sourced and seasonal ingredients. The restaurant's menu has evolved over the years under the leadership of its chef, Daniel Humm. \n\n\nThree Michelin Stars: It had received three Michelin stars, which is the highest rating awarded by the prestigious Michelin Guide, indicating exceptional culinary excellence. \n\n\nWorld's 50 Best Restaurants: The restaurant has consistently ranked among the top restaurants on the list of The World's 50 Best Restaurants.\n\n\nAwards and Accolades: Eleven Madison Park and its chef have received numerous awards and accolades, including the James Beard Foundation Award for Outstanding Restaurant and Outstanding Chef.\n\n\nRenovations and Evolution: The restaurant underwent a significant renovation in 2017, after which it adopted a tasting menu format with a strong emphasis on plant-based cuisine. This marked a departure from its previous à la carte menu.\n\n\nPlease note that the information provided here is based on my last knowledge update in September 2021, and the restaurant may have undergone changes since then. It's advisable to check the latest information and reviews if you plan to visit or learn more about Eleven Madison Park.",
      liveLink: "#",
      image: emp,
      sourceLink: "/blog",
    },
    {
      title: "The Nines",
      theStory: "The Nines is a piano bar and supper club in New York City. \n\n\nIt's located at 9 Great Jones Street, New York, NY 10012. The Nines is a glamorously designed lounge with a rotating roster of players. \n\n\nIt has a dress code and encourages guests to arrive in proper dinner attire. \n\n\nThe Nines has a coat check, velvet seats, and $26 cocktails. \n\n\nIt also serves beer, wine, and hard liquor.\n\n\nThe Nines has an average customer rating of 4.1/5 from 127 reviews. It's a good place for a late-night meal. Some say that Chris Rock, Anne Hathaway, Sienna Miller, and Bradley Cooper have visited The Nines. \n\n\nThe Nines has a price range of $50 to 100.",
      sourceLink: "/blog",
      image: "https://www.we-heart.com/upload-images/ninesnyc8.jpg",
    },
    {
      title: "Cecconi's",
      theStory: "Cecconi's DUMBO is an Italian restaurant in Brooklyn, New York that serves wood-fired pizzas, handmade pastas, and other Italian dishes. The restaurant is known for its cocktails, wine selection, and desserts. It also offers waterfront views.",
      image: "https://cdn.vox-cdn.com/thumbor/ZdN-SL6yEOzNb3SIbRz4vU1Adac=/0x0:4875x3250/1200x0/filters:focal(0x0:4875x3250):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/8680399/AC1I1342__1_.jpg",
      liveLink: "#",
      sourceLink: "/blog",
    },
    {
      title: "Le Coucou",
      theStory: "After taking Paris by storm, internationally acclaimed chef Daniel Rose returns home with Le Coucou. A gracious modern nod to fine European gastronomy, Rose shrinks the intercontinental divide separating the City of Light and the Big Apple, proving that the true essence of fine French dining can thrive in any time zone.",
      image: "https://lecoucou.com/wp-content/uploads/sites/19/2022/10/220711_StarrRestaurants_LeCoucou_0193-1024x683.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Nobu 57",
      theStory: "Nobu Fifty Seven is a Japanese-Peruvian restaurant located at 40 West 57th Street in New York, NY. It was the first New York location for chef Nobu Matsuhisa, and opened in 2005.  \n\n\nThe restaurant is known for its desserts and is popular with tourists and celebrities. It has an average customer rating of 4.4/5 from 2.1K reviews.\n\n\nNobu Fifty Seven is a popular event venue with 4,300 square feet of space. It can accommodate power lunches, intimate dinners, and high-energy receptions. \n\n\nThe restaurant serves Nobu's signature Japanese cuisine, including classic dishes like Tiradito Nobu Style and Black Cod Miso. The menu is designed for sharing, with several plates suggested per person. \n\n\nPopular dishes include Black Cod with Miso, Yellowtail Jalapeno Roll, and Rock Shrimp Tempura. \n\n\nNobu Fifty Seven also has a bar and lounge. It accepts credit cards, debit cards, and contactless payment.",
      image: "https://noburestaurants.com/assets/article-media-files/Nobu-57-Mid-Day-Bar.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Nusret Meatpacking District",
      theStory: "Nobu Fifty Seven is a Japanese-Peruvian restaurant located at 40 West 57th Street in New York, NY. It was the first New York location for chef Nobu Matsuhisa, and opened in 2005.  \n\n\nThe restaurant is known for its desserts and is popular with tourists and celebrities. It has an average customer rating of 4.4/5 from 2.1K reviews.\n\n\nNobu Fifty Seven is a popular event venue with 4,300 square feet of space. It can accommodate power lunches, intimate dinners, and high-energy receptions. \n\n\nThe restaurant serves Nobu's signature Japanese cuisine, including classic dishes like Tiradito Nobu Style and Black Cod Miso. The menu is designed for sharing, with several plates suggested per person. \n\n\nPopular dishes include Black Cod with Miso, Yellowtail Jalapeno Roll, and Rock Shrimp Tempura. \n\n\nNobu Fifty Seven also has a bar and lounge. It accepts credit cards, debit cards, and contactless payment.",
      image: "https://noburestaurants.com/assets/article-media-files/Nobu-57-Mid-Day-Bar.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Nubeluz",
      theStory: "Nubeluz is a cocktail bar and restaurant located on the 50th floor of The Ritz-Carlton New York, NoMad. It opened in September 2022 and is known for its stylish decor and 270-degree views of the New York City skyline. Nubeluz is a popular destination for tourists and is known for its cocktails and wine selection. \n\n\nNubeluz is open Monday–Wednesday and Sunday from 4 PM–12 AM, Thursday–Saturday from 4 PM–1 AM. Reservations are required and can be made online. Tables are held for 15 minutes after the reservation time. \n\n\nNubeluz has a dress code that asks guests to dress in cocktail-appropriate attire. Athletic wear, t-shirts, baseball hats, flip flops, large logos, and overly distressed or revealing clothing are not permitted. \n\n\nNubeluz's most popular dishes include Manhattan, Lobster Roll, and Grilled Cheese. Cocktails range from $20 to $26.",
      image: "https://joseandres.com/wp-content/uploads/2022/09/220912_RITZ_NUBELUZ13452_Bjorn-Wallander-scaled.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Scarpetta",
      theStory: "Scarpetta is an upscale Italian restaurant located at 88 Madison Avenue in The James Hotel in New York City. The restaurant is known for its handmade pastas and sophisticated menu. Some of the most popular dishes include braised octopus, duck and foie gras raviol, and coconut panna cotta.",
      image: "https://www.tgpinternational.com/hs-fs/hubfs/Scarpetta.jpg?width=825&height=550&name=Scarpetta.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Caviar Kaspia",
      theStory: "Caviar Kaspia is a Parisian restaurant and boutique that opened its first US location at The Mark Hotel in New York City. The restaurant is known for its cocktails and caviar, and some say they love the food. The Mark Restaurant is open from 7 AM–1 AM.",
      image: "https://www.foodandwine.com/thmb/dAbe2j5mSh5_pYBivyPZgPm1dtY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/Caviar-Kaspia-Coming-To-NYC-FT-BLOG0422-d7c8f0ffc2cb4091a406b91b4d9f149e.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Le Pavillon",
      theStory: "Le Pavillon is a French restaurant in New York City's Midtown Manhattan. It opened in May 2021 in One Vanderbilt, a skyscraper that was completed in 2020. The restaurant is owned by the Dinex Group, led by chef and restaurateur Daniel Boulud. \n\n\nLe Pavillon is a one-star restaurant in the 2022 MICHELIN Guide USA. It has an average customer rating of 4.7/5 from 405 reviews.",
      image: "https://media.timeout.com/images/105776643/image.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Manhatta",
      theStory: "Manhatta is a New American restaurant and bar located on the 60th floor of 28 Liberty Street in New York, NY. It's a classy place with elegant decor and 360-degree views. Manhatta is a Michelin Guide restaurant and is ranked #83 of 12034 restaurants in New York City on Tripadvisor.",
      image: "https://static01.nyt.com/images/2018/10/10/dining/10Rest1/merlin_144637122_a0b208a7-8fa7-4ae0-ad4a-eb707636aff5-superJumbo.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Overstory",
      theStory: "Overstory is a rooftop cocktail bar in New York City's Financial District. It's located on the 64th floor of 70 Pine Street, one floor above SAGA Restaurant. The bar opened in 2021 and is surrounded by a wraparound terrace with 360-degree views of the city's skyline.",
      image: "https://www.therooftopguide.com/rooftop-bars-in-new-york/Bilder/overstory-600-3.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Electric Lemon",
      theStory: "Electric Lemon is a seasonal American restaurant located on the 24th floor of the Equinox Hotel in New York City's Hudson Yards. It's a contemporary restaurant with a Michelin Guide listing.",
      image: "https://weninnewyork.com/wp-content/uploads/2020/10/Electric-Lemon-Terrace-Equinox-Hotel.jpeg",
      sourceLink: "/blog",
    },
    {
      title: "Peak",
      theStory: "Peak Restaurant & Bar is located on the 101st floor of 30 Hudson Yards in New York City. It's a stylish restaurant and cocktail lounge with panoramic views of the city. ",
      image: "https://nypost.com/wp-content/uploads/sites/2/2021/11/peak-1.jpg?quality=75&strip=all&w=744",
      sourceLink: "/blog",
    },
    {
      title: "Nusret Midtown",
      theStory: "Nusr-Et Steakhouse New York is a steakhouse in New York City's Midtown. It's located at 60 West 53rd Street.",
      image: "https://static01.nyt.com/images/2018/02/07/dining/07Salt3-sub/07Salt3-sub-videoSixteenByNine3000-v2.jpg?year=2018&h=1687&w=3000&s=d391c9c962827c39d85bbb5e6ec01099ef158f8828a980c002db8f6e7faeac1b&k=ZQJBKqZ0VN&tw=1",
      sourceLink: "/blog",
    },
    {
      title: "Saga",
      theStory: "SAGA is a fine dining restaurant in New York City's Financial District. It's located on the 63rd floor of 70 Pine Street, an Art Deco tower. SAGA is the upscale sibling of Crown Shy, a new American restaurant on the ground floor of the same building.",
      image: "https://cdn.vox-cdn.com/thumbor/CnM3gFKCxIxlWokEyklK1iZSpqU=/0x0:5179x3453/1200x0/filters:focal(0x0:5179x3453):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/23118934/Saga_Night_18.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Centurion New York",
      theStory: "The American Express Centurion Lounge in New York is located on the 55th floor of One Vanderbilt. It's open daily from 11 AM to 10 PM.",
      image: "https://thepointsguy.global.ssl.fastly.net/us/originals/2023/01/Centurion_NewYork_1.png",
      sourceLink: "/blog",
    },
    {
      title: "Blue Box Cafe",
      theStory: "The Blue Box Café by Daniel Boulud is a restaurant in New York City's Tiffany & Co. flagship store on Fifth Avenue. The restaurant is located on the 6th floor at 727 5th Avenue.",
      image: "https://media.timeout.com/images/104706764/image.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Baccarat Grand Salon",
      theStory: "The Baccarat Hotel in New York City has a fine dining restaurant and a bar. The restaurant is called Grand Salon and is located on the second floor of the hotel. The hotel also has a Baccarat Bar & Lounge.",
      image: "https://www.baccarathotels.com/sites/default/files/styles/hero_short_xl/public/2018-10/hotel-interior-baccarat_hotel_grand_salon_2-2015.jpg?itok=CdFWInQK",
      sourceLink: "/blog",
    },
    {
      title: "BG Cafe",
      theStory: "The BG cafe is located on the 7th floor of Bergdorf Goodman at 754 5th Avenue, New York, NY 10019. It's known for its tea service and selection, coffee, and dessert. The cafe also serves salads, sandwiches, and breakfast.",
      image: "https://images.ctfassets.net/xxo9lvoc4mg6/mDqt9XERuuQf4gf3PNen8/1fd35f1650cddfa4c365ae83be74d604/30STORES1-jumbo.jpg?w=1000&q=90",
      sourceLink: "/blog",
    },
    {
      title: "Carbone",
      theStory: "Carbone is an Italian-American restaurant in Greenwich Village, New York. It's located at 181 Thompson Street, New York, NY 10012. Their phone number is (212) 254-3000.",
      image: "https://media.newyorker.com/photos/609ed8bdd60710aaa9f4848a/master/pass/Rosner-Carbone.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Sarabeth's",
      theStory: "Sarabeth's is a restaurant chain that started in New York City in 1983. Sarabeth Levine opened her first restaurant on the Upper East Side. The restaurant was so successful that she opened five more. Sarabeth's serves breakfast, lunch, brunch, and dinner seven days a week",
      image: "https://dz0zjhi21dz2t.cloudfront.net/media/81904/photos/86432/2048.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Cipriani",
      theStory: "Cipriani is a family-owned Italian restaurant chain that's known for its high-quality food and atmosphere. The company was founded in 1931 by Giuseppe Cipriani in Venice, Italy.",
      image: "https://resizer.otstatic.com/v2/photos/wide-xlarge/1/47903346.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Sadelle's",
      theStory: "Sadelle's is a New York City brunch restaurant located in SoHo. It's known for its bagels, which some say are the best in the city.",
      image: "https://farm2.staticflickr.com/1447/23985921814_5e8f856d5a_b.jpg",
      sourceLink: "/blog",
    },
    {
      title: "The Palm Court",
      theStory: "The Palm Court is a restaurant at the Plaza Hotel in New York City. It's known for its high-end breakfast, afternoon tea, and evening cocktails. The Palm Court is located at 768 5th Avenue, New York, NY 10019. ",
      image: "https://nypost.com/wp-content/uploads/sites/2/2016/08/120104oakroom2db.jpg?quality=75&strip=all&w=1200",
      sourceLink: "/blog",
    },
    {
      title: "J.G. Melon",
      theStory: "J.G. Melon is a New York City restaurant known for its burgers. It's located at 1291 3rd Avenue on the Upper East Side of Manhattan.",
      image: "https://www.biteofthebest.com/wp-content/uploads/IMG_9015.jpeg",
      sourceLink: "/blog",
    },
    {
      title: "The Mark Restaurant",
      theStory: "The restaurant features a menu of over 70 dishes that blend European classics with global seasonings. The menu is created by Jean-Georges Vongerichten, a renowned New York City chef. ",
      image: "https://themarkhotel.com/app/uploads/2015/04/restaurant_11-2000x1000.jpg",
      sourceLink: "/blog",
    },
    {
      title: "The Campbell",
      theStory: "The Campbell was originally the office of American financier John W. Campbell, who was a member of the New York Central Railroad's board of directors. The space was leased in 1923 from the Vanderbilt family, who built Grand Central. It was used as an office and entertainment hall from the 1920s through the 1950s. It then became a police station, jail, and gun storage before being converted into a bar in 1999. ",
      image: "https://www.exp1.com/blog/wp-content/uploads/sites/7/2019/03/The-Campbell_Bar.jpg",
      sourceLink: "/blog",
    },
    {
      title: "Nello",
      theStory: "Nello is an Italian restaurant on Madison Avenue in New York City. It's located at 696 Madison Avenue, New York, NY 10065. Their phone number is (212) 980-9099. ",
      image: "https://media.gettyimages.com/id/103198055/photo/jay-z-and-kanye-west-dine-at-nellos-restaurant-on-july-30-2010-in-new-york-city.jpg?s=612x612&w=gi&k=20&c=mdWibXUwYq46Fkegl_xnlnaQBeIhOo2-n8pTla3GUI8=",
      sourceLink: "/blog",
    },
    {
      title: "Rubirosa",
      theStory: "Rubirosa is located in the heart of Nolita and its clientele bridges the gap between the old and new neighborhood regulars.",
      image: "https://www.thelondoner.me/wp-content/uploads/2017/03/Rubirosa-New-York-7.jpg",
      sourceLink: "/blog",
    },
    /*{
      title: "Lodi",
      theStory: "",
      liveLink: "#",
      image: ,
      sourceLink: "/blog",
    },
    {
      title: "Lafayette Grand Cafe",
      theStory: "",
      liveLink: "#",
      image: ,
      sourceLink: "/blog",
    },*/
    
    
  ],
  
};
